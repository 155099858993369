<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button type="primary" @click="$refs.dialog.isShow = true">新建
      </a-button>
    </Breadcrumb>

    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-select :disabled="appSelectData.length===1" :value="appSelectParam.appId" style="width: 180px" @select="selectChange"
        >
          <a-select-option v-for="item in appSelectData" :value="item.id" :key="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.code" placeholder="编码"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <a-table :columns="this.tenantId==='0'?columns2:columns" :data-source="list" :rowKey='record=>record.id'
             :pagination="false"
             bordered >
      <span slot="action4" slot-scope="scope">
        {{ scope.tenantId === '0' ? '是' : '否' }}
      </span>
      <span slot="action5" slot-scope="scope">
        {{ scope === '0' ? '正常' : '其他' }}
      </span>
      <span slot="action6" slot-scope="scope">
        {{ scope === 0 ? '全部' : '' }}
      </span>
      <span slot="action" slot-scope="scope">
        <a @click="$refs.dialog.init($utils.clone(scope.id)) ;$refs.dialog.isShow = true">编辑</a>
        <a-divider type="vertical"/>
<!--        <a @click="del(scope)"><a-spin size="small"/>删除</a>-->
                  <a-popconfirm
                    title="确定删除吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirm(scope.id)"
                    @cancel="cancel"
                  >
            <a href="#">删除</a>
          </a-popconfirm>
      </span>
    </a-table>
    <save-role ref="dialog" @save="reloadList" :appSelectParam="this.appSelectParam"
               :menuList="this.menuList"></save-role>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SaveRole from './components/addEditAppRole'

export default {
  components: {
    Breadcrumb,
    SaveRole
  },
  data () {
    return {
      appSelectParam: {
        appId: '',
        appName: ''
      },
      menuList: [],
      appSelectData: [],
      breadList: [
        {
          name: '应用角色管理',
          url: ''
        }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      isQueryLoading: false,
      form: {
        appId: '',
        name: '',
        code: ''
      },
      list: [],
      tenantId: undefined,
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name',
          key: 'name',
          width: '300'
        },
        {
          title: '角色编号',
          dataIndex: 'code',
          key: 'code',
          width: '100'
        },
        {
          title: '排序号',
          dataIndex: 'sort',
          key: 'sort'
        },
        {
          title: '系统角色',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'action4' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'action5' }
        },
        {
          title: '数据权限',
          dataIndex: 'dsType',
          key: 'dsType',
          scopedSlots: { customRender: 'action6' }
        }, {
          title: '备注信息',
          dataIndex: 'memo',
          key: 'memo'
        }, {
          title: '更新时间',
          dataIndex: 'gmtModified',
          width: 200,
          key: 'gmtModified'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          key: 'operation',
          fixed: 'right',
          width: 200,
          ellipsis: true
        }
      ],
      columns2: [
        {
          title: '角色名称',
          dataIndex: 'name',
          key: 'name',
          width: '300'
        },
        {
          title: '角色编号',
          dataIndex: 'code',
          key: 'code',
          width: '100'
        },
        {
          title: '排序号',
          dataIndex: 'sort',
          key: 'sort'
        },
        {
          title: '系统角色',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'action4' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'action5' }
        },
        {
          title: '备注信息',
          dataIndex: 'memo',
          key: 'memo'
        }, {
          title: '更新时间',
          dataIndex: 'gmtModified',
          width: 200,
          key: 'gmtModified'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          key: 'operation',
          fixed: 'right',
          width: 200,
          ellipsis: true
        }
      ]
    }
  },
  created () {
    this.initAppList()
    this.initData()
    this.tenantId = this.$store.state.user.currentTenantId
    console.log('当前租户id', this.tenantId)
    this.tenantName = this.$store.state.user.currentTenantName
    console.log('当前租户名称', this.tenantName)
  },
  methods: {
    // 选择应用查询.
    selectChange (value, e) {
      console.log(`selected ${value}`)
      console.log('selected2', JSON.stringify(e.key))
      this.appSelectParam.appId = value
      this.appSelectParam.appName = e.key
      this.loadList()
      this.initMenuTree()
    },
    // 查询应用.queryAppListByTenantId
    async initAppList () {
      this.appSelectData = await this.$store.dispatch('http', {
        api: 'queryAppListByTenantId'
      })
      if (this.appSelectData.length > 0) {
        this.appSelectParam.appId = this.appSelectData[0].id
        this.appSelectParam.appName = this.appSelectData[0].name
        this.initMenuTree()
      }
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.del(id)
    },
    cancel (values) {
      console.log(values)
    },
    async initMenuTree () {
      console.log('xsxxsxsxsx', this.appSelectParam.appId)
      this.form.appId = this.appSelectParam.appId
      const params = this.form
      this.menuList = await this.$store.dispatch('http', {
        api: 'queryAppMenuList',
        params,
        complete: () => {

        }
      })
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    callback (key) {
      this.form.tagCode = key
      this.activeKey = key
      this.queryList()
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: '',
        code: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      this.form.appId = this.appSelectParam.appId
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      console.log('参数：', JSON.stringify(this.appSelectParam))
      const result = await this.$store.dispatch('http', {
        api: 'queryAppRoleList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.list = result.records
      this.pagination.total = result.total

      console.log('decscsd', JSON.stringify(this.list))
    },
    async initData () {
      await this.initAppList()
      const params = this.appSelectParam
      const result = await this.$store.dispatch('http', {
        api: 'queryAppRoleList',
        params,
        complete: () => {
        }
      })
      this.list = result.records
    },
    async del (id) {
      const res = await this.$store.dispatch('http', {
        api: 'removeAppRole',
        query: {
          id: id
        }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除成功'
        })
        // this.queryData(10) // 传入数字 重置列表不会更改当前页
        this.reloadList() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}

</style>

<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="系统" prop="">
        <a-input :value="appSelectParam.appName" disabled/>
      </a-form-model-item>
      <a-form-model-item label="ID" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="角色名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="角色编码" prop="code">
        <a-input v-model="form.code"/>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="memo">
        <a-textarea v-model="form.memo" placeholder="请输入">
        </a-textarea>
      </a-form-model-item>
      <a-form-model-item label="授权菜单" prop="menuIds">
        <a-tree
          v-model="form.menuIds"
          checkable
          halfChecked
          checkStrictly
          :tree-data="menuList"
          :replaceFields="replaceFields"
          @check="checkMenus"
        />
      </a-form-model-item>
<!--      v-show="this.tenantId!=='0'-->
      <a-form-model-item v-show="false" label="数据权限类型">
        <a-select :default-value="0" style="width: 120px">
          <a-select-option v-for="item in dsTypeList" :key="item.code">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-show="false" label="数据范围" prop="dsScope">
        <a-input v-model="form.dsScope"/>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort">
        <a-input-number style="width:100%;" v-model="form.sort"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  props: ['appSelectParam', 'menuList'],
  data () {
    return {
      tenantId: undefined,
      checkedData: [],
      dsTypeList: [{
        code: 0,
        name: '全部'
      }],
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      tempId: undefined,
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      // 模拟标签
      options: [
        {
          tagName: '电商',
          tagCode: 'DS'
        },
        {
          tagName: '内容',
          tagCode: 'NR'
        }
      ],
      form: {
        id: undefined,
        name: '',
        code: this.$utils.codeGenerator('role_', 6),
        dsScope: '',
        dsType: 0,
        menuIds: [],
        memo: '',
        sort: '',
        appId: undefined,
        tenantId: undefined
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }],
        code: [{
          required: true,
          message: '编号不能为空',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '排序号不能为空',
          trigger: 'change'
        }],
        appVersion: [{
          required: true,
          message: '版本号不能为空',
          trigger: 'change'
        }],
        preUrl: [{
          required: true,
          message: '访问url不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  watch: {
    isShow (val) {

    }
  },
  created () {
    this.tenantId = this.$store.state.user.currentTenantId
    var abc = this.$utils.codeGenerator('abc', 6)
    console.log(abc, 'codeGener')
  },
  computed: {},
  methods: {
    checkMenus (checkedData, e) {
      this.form.menuIds = checkedData.checked
      console.log('选中：', JSON.stringify(checkedData.checked))
    },
    init (item) {
      this.tempId = item
      this.getRoleInfo(this.tempId)
    },
    // 获取详情.
    async getRoleInfo (tempId) {
      const res = await this.$store.dispatch('http', {
        api: 'queryAppRoleInfo',
        query: {
          id: tempId
        },
        complete: () => {
        }
      })
      this.form = res
      this.checkedData = this.form.menuIds
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        id: undefined,
        name: '',
        code: this.$utils.codeGenerator('role_', 6),
        dsScope: '',
        dsType: 0,
        menuIds: [],
        memo: '',
        sort: '',
        appId: undefined,
        tenantId: undefined
      }
    },
    // 提交保存.
    async determine () {
      this.form.appId = this.appSelectParam.appId
      console.log('提交内容:', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'saveAppRole'
          if (this.form.id) {
            api = 'updateAppRole'
            params.id = this.form.id
          } else {
            delete params.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '编辑' : '新增') + '应用成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
